import React from 'react'

import PropTypes from 'prop-types'

import './secondary-button.css'

const SecondaryButton = (props) => {
  return (
    <div className="secondary-button-container">
      <a
        href="https://www.youtube.com/@TrueTalesTube"
        target="_blank"
        rel="noreferrer noopener"
        className="secondary-button-link button ButtonSmall"
      >
        {props.button}
      </a>
    </div>
  )
}

SecondaryButton.defaultProps = {
  button: 'subscribe',
}

SecondaryButton.propTypes = {
  button: PropTypes.string,
}

export default SecondaryButton
