import React from 'react'

import PropTypes from 'prop-types'

import './outline-gray-button.css'

const OutlineGrayButton = (props) => {
  return (
    // <div className="outline-gray-button-container">
    //   <button className="outline-gray-button-button button ButtonSmall">
    //     {props.button}
    //   </button>
    // </div>
    <div></div>
  )
}

OutlineGrayButton.defaultProps = {
  button: 'Button',
}

OutlineGrayButton.propTypes = {
  button: PropTypes.string,
}

export default OutlineGrayButton
