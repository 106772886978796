import React from 'react'

import { Helmet } from 'react-helmet'

import Header from '../components/header'
import PrimaryPinkButton from '../components/primary-pink-button'
import OutlineGrayButton from '../components/outline-gray-button'
import Footer from '../components/footer'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Stories2Life</title>
        <meta property="og:title" content="Stories2Life" />
      </Helmet>
      <Header></Header>
      <div className="home-hero">
        <div className="home-container01">
          <div className="home-card">
            <h1 className="home-text">
              Stories create worlds
            </h1>
            <h1 className="home-text01">
              Story by story, bringing imagination to life
            </h1>
            <span className="home-text02">
              Stories|Script|Voice Over|Transform
            </span>
            {/* <div className="home-container02">
              <div className="home-container03">
                <PrimaryPinkButton button="contact us"></PrimaryPinkButton>
              </div>
              <OutlineGrayButton button="read more"></OutlineGrayButton>
            </div> */}
          </div>
        </div>
      </div>
      <img
        alt="image"
        src="/playground_assets/curved6-1500h.jpg"
        className="home-image"
      />
      <section className="home-container04">
        <div className="home-container05">
          <h1 className="home-text03 HeadingOne">From words to stories, stories to visuals</h1>
          <span className="home-text04">
            All of us have stories to say. We simplify the process and help you
            build ,publish and monetize your content 
          </span>
        </div>
        <div className="home-container06">
          <div className="home-container07">
            <img
              alt="image"
              src="/playground_assets/Ai_5500x3366.jpg"
              className="home-image01"
            />
            <span className="home-text05">
              <span>
                At stories to life our Motto is Content plus technology brings
                stories to life at a fraction of cost and time.
              </span>
              <br className="Small"></br>
              <span className="Small">-Team</span>
            </span>
            <div className="home-container08">
              <img
                alt="image"
                src="/playground_assets/Person_3024x4032.jpg"
                className="home-image02"
              />
            </div>
          </div>
          <div className="home-container09">
            <img
              alt="image"
              src="/playground_assets/Neal_3024x4032.jpg"
              className="home-image03"
            />
            <div className="home-container10">
              <h3>Under the Hood</h3>
              <p>
                <span>
                  As advocates of responsible AI, we believe in using AI
                  technology ethically and responsibly. We understand that AI
                  can have a significant impact on society, and we strive to
                  ensure that the stories we create using AI are accurate,
                  factual, and reflect the values of our clients and their
                  brands.
                </span>
                <br></br>
                <span>
                  To achieve this, we have implemented several measures to
                  ensure the accuracy and factualness of our stories. Our AI
                  models are trained on a diverse range of data sets and are
                  continuously updated to improve their accuracy and relevance.
                  We also have a team of human editors who review and verify the
                  accuracy of the content generated by our AI models.In
                  addition, we have built nifty plugins to ensure seamless
                  transitions between voice, image, and video elements in our
                  stories. These plugins use AI algorithms to analyze the
                  context of the content and suggest the best transitions based
                  on the story&apos;s flow and the emotions that the story aims
                  to evoke.
                </span>
                <br></br>
                <span>
                  Our plugins also ensure that the voice, image, and video
                  elements are synchronized correctly to create a seamless and
                  engaging story. We understand that storytelling is an art
                  form, and our plugins aim to enhance the creative process by
                  providing a more streamlined and efficient workflow.
                </span>
                <br></br>
                <span>
                  Overall, our goal is to leverage AI technology to create
                  engaging and accurate stories while maintaining a high
                  standard of responsibility and ethical conduct. 
                </span>
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="home-testimonials">
        <div className="home-container11">
          <div className="home-container12">
            <div className="home-container13">
              <h2 className="home-text18 HeadingOne">Work with us</h2>
              <p className="home-text19 Lead">
                <span className="home-text20">
                  Whatever your qualification is - we got you!
                </span>
              </p>
              <p className="home-text21 Body">
                We are a mix bag, we bring complimentory skills to the table .
                We are forever learning . We are in the process of building a
                great team.
              </p>
              <p className="home-text22">Mark Jojansen</p>
              <p className="home-text23 Small">Project manager, Atlassian</p>
              <div className="home-container14">
                <img
                  alt="image"
                  src="/playground_assets/team-4-200h.jpg"
                  className="home-image04"
                />
                <div className="home-container15"></div>
                <img
                  alt="image"
                  src="/playground_assets/team-3-200h.jpg"
                  className="home-image05"
                />
                <div className="home-container16"></div>
                <img
                  alt="image"
                  src="/playground_assets/team-2-200h.jpg"
                  className="home-image06"
                />
              </div>
            </div>
          </div>
          <div className="home-logos">
            <div className="home-container17">
              <div className="home-container18">
                <div className="home-container19">
                  <img
                    alt="image"
                    src="/playground_assets/logo-asana.svg"
                    className="home-image07"
                  />
                </div>
                <div className="home-container20">
                  <img
                    alt="image"
                    src="/playground_assets/logo-slack.svg"
                    className="home-image08"
                  />
                </div>
                <div className="home-container21">
                  <img
                    alt="image"
                    src="/playground_assets/logo-google-drive.svg"
                    className="home-image09"
                  />
                </div>
              </div>
              <div className="home-container22">
                <div className="home-container23">
                  <img
                    alt="image"
                    src="/playground_assets/logo-shopify.svg"
                    className="home-image10"
                  />
                </div>
                <div className="home-container24">
                  <img
                    alt="image"
                    src="https://demos.creative-tim.com/soft-ui-design-system-pro/assets/img/logos/small-logos/logo-apple.svg"
                    className="home-image11"
                  />
                </div>
                <div className="home-container25">
                  <img
                    alt="image"
                    src="/playground_assets/logo-invision.svg"
                    className="home-image12"
                  />
                </div>
              </div>
              <div className="home-container26">
                <div className="home-container27">
                  <img
                    alt="image"
                    src="/playground_assets/logo-attlasian.svg"
                    className="home-image13"
                  />
                </div>
                <div className="home-container28">
                  <img
                    alt="image"
                    src="/playground_assets/logo-weave.svg"
                    className="home-image14"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        { <img
          alt="image"
          src="/playground_assets/bottom.svg"
          className="home-bottom-wave-image"
        /> }
        <img
          alt="image"
          src="/playground_assets/waves-white.svg"
          className="home-image15"
        />
        <img
          alt="image"
          src="/playground_assets/top.svg"
          className="home-top-wave-image"
        />
      </section> */}
      <Footer></Footer>
    </div>
  )
}

export default Home
