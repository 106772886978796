import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route } from 'react-router-dom'

import './style.css'
import Portfolio from './views/portfolio'
import Profile from './views/profile'
import AboutUs from './views/aboutus'
import ContactUs from './views/contactus'
import Home from './views/home'

const App = () => {
  return (
    <Router>
      <div>
        <Route component={Portfolio} exact path="/portfolio" />
        <Route component={Profile} exact path="/profile" />
        <Route component={AboutUs} exact path="/aboutus" />
        <Route component={ContactUs} exact path="/contactus" />
        <Route component={Home} exact path="/" />
      </div>
    </Router>
  )
}

ReactDOM.render(<App />, document.getElementById('app'))
