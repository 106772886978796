import React from 'react'

import { Helmet } from 'react-helmet'

import Header from '../components/header'
import OutlineBlueButton from '../components/outline-blue-button'
import NextButton from '../components/next-button'
import BlogCard from '../components/blog-card'
import PrimaryBlueButton from '../components/primary-blue-button'
import Footer from '../components/footer'
import './contactus.css'

const Profile = (props) => {
  return (
    <div className="profile-container">
      <Helmet>
        <title>Contact Us - Stories2Life</title>
        <meta property="og:title" content="Contact Us - Stories2Life" />
      </Helmet>
      <Header></Header>
      <div className="contactus-blog-posts">
        <div className="contactus-container01">
          <div className="profile-container02">
            <h4 className="profile-text">Stories2Life Digital Private Limited</h4>
          </div>
          <span className="Medium">
                NO 28/A, Smart Avenue, <br></br>
                80 feet road, Micheal Palya, <br></br>
                Indira nagar, Bangalore- 560038
          </span>
          <span className="Medium">
                Email: vineeta.shenoy@gmail.com
          </span>
          {/* <NextButton button="Email Us" to="mailto:mailto:vineeta.shenoy@gmail.com"></NextButton> */}
        </div>
      </div>
      <Footer></Footer>
    </div>
  )
}

export default Profile
